

/********** Template CSS **********/
:root {
  --primary: #198754;
  --secondary: #F57E57;
  --light: #EEF9FF;
  --dark: #091E3E;
}

h1,
h2,
.font-weight-bold {
  font-weight: 700 !important;
}

h3,
h4,
.font-weight-semi-bold {
  font-weight: 600 !important;
}

h5,
h6,
.font-weight-medium {
  font-weight: 500 !important;
}

.btn {
  font-family: 'Jost', sans-serif;
  font-weight: 600;
  transition: .5s;
}

.btn-primary,
.btn-secondary {
  color: #FFFFFF;
}

.btn-primary:hover {
  background: var(--secondary);
  border-color: var(--secondary);
}

.btn-secondary:hover {
  background: var(--primary);
  border-color: var(--primary);
}

.btn-square {
  width: 36px;
  height: 36px;
}

.btn-sm-square {
  width: 28px;
  height: 28px;
}

.btn-lg-square {
  width: 46px;
  height: 46px;
}

.btn-square,
.btn-sm-square,
.btn-lg-square {
  padding-left: 0;
  padding-right: 0;
  text-align: center;
}

#spinner {
  opacity: 0;
  visibility: hidden;
  transition: opacity .5s ease-out, visibility 0s linear .5s;
  z-index: 99999;
}

#spinner.show {
  transition: opacity .5s ease-out, visibility 0s linear 0s;
  visibility: visible;
  opacity: 1;
}

.back-to-top {
  position: fixed;
  display: none;
  right: 45px;
  bottom: 45px;
  z-index: 99;
}

.top-shape::before {
  position: absolute;
  content: "";
  width: 35px;
  height: 100%;
  top: 0;
  left: -17px;
  background: var(--primary);
  transform: skew(40deg);
}

.navbar-light .navbar-nav .nav-link {
  font-family: 'Jost', sans-serif;
  padding: 35px 15px;
  font-size: 18px;
  color: var(--dark);
  outline: none;
  transition: .5s;
}

.sticky-top.navbar-light .navbar-nav .nav-link {
  padding: 20px 15px;
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link.active {
  color: var(--primary);
}

@media (max-width: 991.98px) {
  .navbar-light .navbar-nav .nav-link,
  .sticky-top.navbar-light .navbar-nav .nav-link {
      padding: 10px 0;
  }
}

.carousel-caption {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(9, 30, 62, .85);
  z-index: 1;
}

@media (max-width: 576px) {
  .carousel-caption h5 {
      font-size: 14px;
      font-weight: 500 !important;
  }

  .carousel-caption h1 {
      font-size: 30px;
      font-weight: 600 !important;
  }
}

.carousel-control-prev,
.carousel-control-next {
  width: 10%;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  width: 3rem;
  height: 3rem;
}

@media (min-width: 991.98px) {
  .banner {
      position: relative;
      margin-top: -90px;
      z-index: 1;
  }
}

.section-title h5::before {
  position: absolute;
  content: "";
  width: 45px;
  height: 3px;
  right: -55px;
  bottom: 11px;
  background: var(--primary);
}

.section-title h5::after {
  position: absolute;
  content: "";
  width: 15px;
  height: 3px;
  right: -75px;
  bottom: 11px;
  background: var(--secondary);
}

.twentytwenty-wrapper {
  height: 100%;
}

.hero-header {
  background: linear-gradient(rgba(9, 30, 62, .85), rgba(9, 30, 62, .85)), url(/public/assets/img/carousel-1.jpg) center center no-repeat;
  background-size: cover;
}

.bg-appointment {
  background: linear-gradient(rgba(9, 30, 62, .85), rgba(9, 30, 62, .85)), url(/public/assets/img/carousel-1.jpg) center center no-repeat;
  background-size: cover;
}

.appointment-form {
  background: rgba(25,135,84, .7);
  /* background: rgba(6, 163, 218, .7); */
}

.service-item img,
.service-item .bg-light,
.service-item .bg-light h5,
.team-item .team-text {
  transition: .5s;
}

.service-item:hover img {
  transform: scale(1.15);
}

.team-item .team-text::after,
.service-item .bg-light::after {
  position: absolute;
  content: "";
  top: 50%;
  bottom: 0;
  left: 15px;
  right: 15px;
  border-radius:100px / 15px;
  box-shadow: 0 0 15px rgba(0, 0, 0, .7);
  opacity: 0;
  transition: .5s;
  z-index: -1;
}

.team-item:hover .team-text::after,
.service-item:hover .bg-light::after {
  opacity: 1;
}

.bg-offer {
  background:url(/public/assets/img/hear_check1.jpg) center center no-repeat;
  background-size: cover;
}

.offer-text {
  background: rgba(25,135,84, .85);
  /* background: rgba(6, 163, 218, .85); */
}

.price-carousel .owl-nav {
  position: absolute;
  width: calc(100% + 45px);
  height: 45px;
  top: calc(50% - 22.5px);
  left: -22.5px;
  display: flex;
  justify-content: space-between;
  opacity: 0;
  transition: .5s;
}

.price-carousel:hover .owl-nav {
  opacity: 1;
}

.price-carousel .owl-nav .owl-prev,
.price-carousel .owl-nav .owl-next {
  position: relative;
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFFFFF;
  background: var(--primary);
  border-radius: 2px;
  font-size: 22px;
  transition: .5s;
}

.price-carousel .owl-nav .owl-prev:hover,
.price-carousel .owl-nav .owl-next:hover {
  background: var(--secondary);
}

.bg-testimonial {
  background: url(/public/assets/img/carousel-2.jpg) center center no-repeat;
  background-size: cover;
}

.testimonial-carousel {
  background: rgba(25,135,84, .85);
  /* background: rgba(6, 163, 218, .85); */

}

.testimonial-carousel .owl-nav {
  position: absolute;
  width: calc(100% + 46px);
  height: 46px;
  top: calc(50% - 23px);
  left: -23px;
  display: flex;
  justify-content: space-between;
  z-index: 1;
}

.testimonial-carousel .owl-nav .owl-prev,
.testimonial-carousel .owl-nav .owl-next {
  position: relative;
  width: 46px;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFFFFF;
  background: var(--primary);
  border-radius: 2px;
  font-size: 22px;
  transition: .5s;
}

.testimonial-carousel .owl-nav .owl-prev:hover,
.testimonial-carousel .owl-nav .owl-next:hover {
  background: var(--secondary);
}

.testimonial-carousel .owl-item img {
  width: 120px;
  height: 120px;
}

.zindex{
  z-index: 1000;
}